import React from "react";
import "../../sass/zeplin/Card.scss";

const Card = ({ shadow = false, isSelected, isSelectable, children, style, ...rest }) => {
  return (
    <div
      style={{ ...style }}
      {...rest}
      className={
        "Card" +
        (isSelectable ? " --selectable" : "") +
        (isSelected ? " --selected" : "") +
          (shadow ? " Shadow" : "") + ( rest.className ? ` ${ rest.className }` : '' )
      }
    >
      {children}
    </div>
  );
};

export default Card;
