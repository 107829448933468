import React from "react";
import "./ConfirmStyling.css";
import * as EmailService from "../../services/emailService";
//import loaderImg from "../../assets/images/newloader.gif";

class ReferralConfirm extends React.Component {
    state = {
        token: ""
    }
  componentDidMount() {
    var guid = this.props.match.params.token;
    var regEx = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (regEx.test(guid) === true) {
      EmailService.referallAcct(guid)
        .then(this.onConfirmSuccess)
        .catch(this.onConfirmFailure);
      // setTimeout(() => {
      //   this.props.history.push("/rep/premier");
      // }, 6000);
    }
    // else {
    //   this.props.history.push("/Error-404");
    // }
  }

  onConfirmSuccess = (e) => {
      console.log(e, "GUID")
      let id = e.item.email
      sessionStorage.setItem('referralId', id);
      this.push(id)
  };

  push = (id) =>{
          setTimeout(() => {
        this.props.history.push("/rep/premier", id);
      }, 1000);
  }

  onConfirmFailure = () => {
    this.props.history.push("/Error-404");
  };

  render() {
    return (
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          <div id="centerIt">
            <h1>
              <b>Referral</b>
            </h1>
            <p>
              <b>{`Welcome`}</b>
            </p>
            <p>
              <b>{`In a few seconds you will be redirected to the registratioin page.`}</b>
            </p>
            <img
              className="loadingImage"
              height="30"
              width="30"
              src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/email_myRep.jpg"
              alt="#"
            />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default ReferralConfirm;