import React, { Component } from "react";
import * as validationHelper from "../../helpers/validation.helper";
import "../../css/join.css";
import BasicForm from "../wizard/BasicForm";
import * as userServices from "../../services/userServices.js";
import swal from "sweetalert";
import Header from "../Header";
import MainFooter from "../layout/MainFooter";
import { Link } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "../payments/Checkout";
import { Modal } from "react-bootstrap";
import TopLogo from "../common/TopLogo";
import { logOut } from "../../services/userServices";

import "../../sass/zeplin/RegisterClient.scss";

class RegisterClient extends Component {
  constructor(props) {
    super(props);

    const formData = this.initValidation(props);
    this.state = {
      formData: formData,
      currentStep: 1,
      formValid: false,
      products: ["input-0"],
      formSubmitted: false,
      customerId: "",
      acctType: null,
      termsModal: false,
      agree: false,
      privacyModal: false,
      privacy: false,
    };

    this.onChange = validationHelper.onChange.bind(this);
    // this.registerUser = this.registerUser.bind(this);
    this.confirmPasswordsMatch = this.confirmPasswordsMatch.bind(this);
    this.addProduct = this.addProduct.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const formData = this.initValidation(nextProps);
    this.setState({ formData: formData });
  }

  addProduct() {
    const newProduct = `input-${this.state.products.length}`;
    this.setState((prevState) => ({
      products: prevState.products.concat([newProduct]),
    }));
  }

  initValidation(props) {
    // const user = props.formData && props.formData._id ? props.formData : {};

    const initializedUser = {
      email: "",
      password: "",
      confirmPassword: "",
    };

    let formData = {
      password: {
        originalValue: initializedUser.password,
        value: initializedUser.password,
        valid: true,
        validation: {
          required: true,
        },
        touched: false,
      },
      email: {
        originalValue: initializedUser.email,
        value: initializedUser.email,
        valid: true,
        validation: {
          required: true,
        },
        touched: false,
      },
      confirmPassword: {
        originalValue: initializedUser.confirmPassword,
        value: initializedUser.confirmPassword,
        valid: true,
        validation: {
          required: true,
        },
        touched: false,
      },
    };

    for (let fieldName in formData) {
      const field = formData[fieldName];
      field.valid = validationHelper.validate(field.value, field.validation);
    }

    return formData;
  }

  confirmPasswordsMatch(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState((prevState) => {
      const field = { ...prevState.formData[name] };
      field.value = value;
      field.touched = true;
      field.valid = this.state.formData.password.value === value;
      const formData = { ...prevState.formData, [name]: field };
      let formValid = true;
      for (let inputIdentifier in formData) {
        formValid = formValid && formData[inputIdentifier].valid;
      }
      return { formData: formData, formValid: formValid };
    });
  }

  submitRegister = (e, data) => {
    if (this.state.agree === true && this.state.privacy === true) {
      let payload = {
        Email: this.state.formData.email.value,
        Password: this.state.formData.password.value,
        ConfirmPassword: this.state.formData.confirmPassword.value,
        RoleId: 2,
        UserStatusId: 2,
      };
      userServices
        .register(payload)
        .then(this.onSuccessResponse)
        .catch(this.onErrorResponse);
    } else swal("Error", "Must Agree to Terms & Privacy", "error");
  };

  handlePayment = (cardElement, customerId, priceId) => {};

  onSuccessResponse = (response) => {
    swal({
      icon: "success",
      title: "You're registered",
    });
    logOut();
    setTimeout(()=>{
      window.location.replace("/check/email");
    },1000)

  };

  onErrorResponse = (error) => {
    // console.log(error, "error")
    // console.log(error.response.data, "data");
    // console.log(error.response.status, "status");
    let status = error.response.status;
    if (status === 400) {
      swal("Error", "Please Check Password", "error");
    } else {
      // console.log(error.response.headers);
      let err = error.response.data.errors[0];
      swal("Error", err, "error");
    }
  };

  handleModal = () => {
    this.setState({
      termsModal: true,
    });
  };

  handlePrivacyModal = () => {
    this.setState({
      privacyModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      termsModal: false,
    });
  };

  handleClosePrivacyModal = () => {
    this.setState({
      privacyModal: false,
    });
  };

  agreeState = () => {
    this.setState((prevState) => ({
      agree: !prevState.agree,
    }));
  };

  agreePrivacy = () => {
    this.setState((prevState) => ({
      privacy: !prevState.privacy,
    }));
  };

  render() {
    return (
      <React.Fragment>
        {/* TERMS MODAL */}
        <Modal
          show={this.state.termsModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Terms</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <embed
              src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/terms.pdf"
              width="750px"
              height="2100px"
            />
            {/* <iframe src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/terms.pdf" style="width:600px; height:500px;" frameborder="0"></iframe> */}
          </Modal.Body>
          <Modal.Footer>
            <btn className="btn-primary btn-pill" onClick={this.handleCloseModal}>
              Close
            </btn>
          </Modal.Footer>
        </Modal>

        {/* Privacy Modal */}
        <Modal
          show={this.state.privacyModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Privacy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <embed
              src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/privacy.pdf"
              width="750px"
              height="2100px"
            />
            {/* <iframe src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/terms.pdf" style="width:600px; height:500px;" frameborder="0"></iframe> */}
          </Modal.Body>
          <Modal.Footer>
            <btn className="btn-primary btn-pill" onClick={this.handleClosePrivacyModal}>
              Close
            </btn>
          </Modal.Footer>
        </Modal>
        <TopLogo show={true} />
        <div className="RegisterClient">
          <div
            style={{
              margin: "250px auto 0 auto",
              width: 800,
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>Create your account</h2>
            <BasicForm
              currentStep={this.state.currentStep}
              handleChange={this.onChange}
              formData={this.state.formData}
              handleSubmit={this.submitRegister}
              handleTermsModal={this.handleModal}
              handlePrivacyModal={this.handlePrivacyModal}
              agreeState={this.agreeState}
              agreePrivacy={this.agreePrivacy}
            />{" "}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterClient;
