import axios from "axios";
import * as helper from "./serviceHelper";

let { REACT_APP_API_URL } = process.env;
let endpoints = {};
endpoints.email = REACT_APP_API_URL + "/user/";
endpoints.appt = REACT_APP_API_URL + "/rep/calendar";
endpoints.referral = REACT_APP_API_URL + "/";

let confirmAcct = (guid) => {
  const config = {
    method: "GET",
    url: endpoints.email + guid,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let userProfileConfirm = (guid) => {
  const config = {
    method: "GET",
    url: endpoints.email + "confirmProfile/" + guid,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let referallAcct = (guid) => {
  console.log(guid, "AXIOS");
  const config = {
    method: "GET",
    url: endpoints.email + "premier/" + guid,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let forgotPass = (email) => {
  console.log(email, "forgot");
  const config = {
    method: "GET",
    url: endpoints.email + "forgot?email=" + email,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let resetPass = (payload) => {
  const config = {
    method: "POST",
    url: endpoints.email + "reset",
    data: payload,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let emailRep = (payload) => {
  console.log(payload, "AXIOS");
  const config = {
    method: "POST",
    url: endpoints.email + "email",
    data: payload,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let emailAllRep = (payload) => {
  console.log(payload, "All");
  const config = {
    method: "POST",
    url: endpoints.appt + "/emailreps",
    data: payload,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let chosenRestaurant = (conf) => {
  const config = {
    data: conf,
    method: "POST",
    url: endpoints.appt + "/final",
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let apptEmail = (data) => {
  const config = {
    data: data,
    method: "POST",
    url: endpoints.appt + "/apptEmail",
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

export {
  referallAcct,
  emailAllRep,
  confirmAcct,
  userProfileConfirm,
  emailRep,
  forgotPass,
  chosenRestaurant,
  resetPass,
  apptEmail,
};
