import React, { Component } from "react";
import * as validationHelper from "../../helpers/validation.helper";
import BasicForm from "../wizard/BasicForm";
import * as userServices from "../../services/userServices.js";
import swal from "sweetalert";
import Header from "../Header";
import MainFooter from "../layout/MainFooter";
import { Link, withRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "../payments/Checkout";
import { Modal, Button } from "react-bootstrap";
import TopLogo from "../common/TopLogo";
import Logo from "../common/Logo";
import { logOut } from "../../services/userServices";
// require("history").createBrowserHistory;

// const stripePromise = loadStripe("pk_test_rdgE7BpleVrniMg8Y0wbSJTp00boSrO3bm");

class RegisterBasic extends Component {
  constructor(props) {
    super(props);

    const formData = this.initValidation(props);
    this.state = {
      formData: formData,
      currentStep: 1,
      formValid: false,
      products: ["input-0"],
      formSubmitted: false,
      customerId: "",
      acctType: null,
      termsModal: false,
      agree: false,
      privacyModal: false,
      privacy: false,
    };

    this.onChange = validationHelper.onChange.bind(this);
    // this.registerUser = this.registerUser.bind(this);
    this.confirmPasswordsMatch = this.confirmPasswordsMatch.bind(this);
    this.addProduct = this.addProduct.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const formData = this.initValidation(nextProps);
    this.setState({ formData: formData });
  }

  addProduct() {
    const newProduct = `input-${this.state.products.length}`;
    this.setState((prevState) => ({
      products: prevState.products.concat([newProduct]),
    }));
  }

  initValidation(props) {
    // const user = props.formData && props.formData._id ? props.formData : {};

    const initializedUser = {
      email: "",
      password: "",
      confirmPassword: "",
    };

    let formData = {
      password: {
        originalValue: initializedUser.password,
        value: initializedUser.password,
        valid: true,
        validation: {
          required: true,
        },
        touched: false,
      },
      email: {
        originalValue: initializedUser.email,
        value: initializedUser.email,
        valid: true,
        validation: {
          required: true,
        },
        touched: false,
      },
      confirmPassword: {
        originalValue: initializedUser.confirmPassword,
        value: initializedUser.confirmPassword,
        valid: true,
        validation: {
          required: true,
        },
        touched: false,
      },
    };

    for (let fieldName in formData) {
      const field = formData[fieldName];
      field.valid = validationHelper.validate(field.value, field.validation);
    }

    return formData;
  }

  confirmPasswordsMatch(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState((prevState) => {
      const field = { ...prevState.formData[name] };
      field.value = value;
      field.touched = true;
      field.valid = this.state.formData.password.value === value;
      const formData = { ...prevState.formData, [name]: field };
      let formValid = true;
      for (let inputIdentifier in formData) {
        formValid = formValid && formData[inputIdentifier].valid;
      }
      return { formData: formData, formValid: formValid };
    });
  }

  submitRegister = (e, data) => {
    if (this.state.agree === true && this.state.privacy === true) {
      let payload = {
        Email: this.state.formData.email.value,
        Password: this.state.formData.password.value,
        ConfirmPassword: this.state.formData.confirmPassword.value,
        RoleId: 3,
        UserStatusId: 2,
      };
      userServices
        .register(payload)
        .then(this.onSuccessResponse)
        .catch(this.onErrorResponse);
    } else swal("Error", "You must agree to terms and privacy", "error");
  };

  handlePayment = (cardElement, customerId, priceId) => {};

  onSuccessResponse = (response) => {
    // Redirect to the page that shows the user what to do next (i.e. check their email for the verification message)
    // this.props.history.push('/check/email');
    logOut();
    setTimeout(()=>{
      window.location.replace("/check/email");
    },1000) // TODO invalidate the comment below by updating state
    // this.props.history.push('/login'); // don't use a router redirect, as we need to reload the current user in state. Otherwise an infinite redirect will occur
  };

  onErrorResponse = (error) => {
    // console.log(error, "error")
    // console.log(error.response.data, "data");
    // console.log(error.response.status, "status");
    console.error(error);
    if (error.response) {
      let status = error.response.status;
      if (status === 400) {
        swal("Error", "Please check your passwords match", "error");
      } else {
        // console.log(error.response.headers);
        let err = error.response.data.errors[0];
        swal("Error", err, "error");
      }
    }
  };

  handleModal = () => {
    this.setState({
      termsModal: true,
    });
  };

  handlePrivacyModal = () => {
    this.setState({
      privacyModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      termsModal: false,
    });
  };

  handleClosePrivacyModal = () => {
    this.setState({
      privacyModal: false,
    });
  };

  agreeState = () => {
    this.setState((prevState) => ({
      agree: !prevState.agree,
    }));
  };

  agreePrivacy = () => {
    this.setState((prevState) => ({
      privacy: !prevState.privacy,
    }));
  };

  render() {
    return (
      <React.Fragment>
        {/* TERMS MODAL */}
        <Modal
          show={this.state.termsModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => this.setState({ termsModal: false })}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Terms</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <embed
              src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/terms.pdf"
              style={{ width: 750, height: 2100, maxHeight: "100vh", maxWidth: "100%" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="Button" onClick={this.handleCloseModal}>
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Privacy Modal */}
        <Modal
          show={this.state.privacyModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => this.setState({ privacyModal: false })}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Privacy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <embed
              src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/privacy.pdf"
              width="750px"
              height="2100px"
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="Button" onClick={this.handleClosePrivacyModal}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <TopLogo />

        <div style={{ marginBottom: 42 }}>
          <Logo
            style={{ margin: "42px auto 0 auto", maxWidth: "50%", width: 168 }}
            className="Logo notMobile"
          />
          <h2
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 32,
              marginTop: 40,
              marginBottom: 32,
            }}
          >
            Create your account
          </h2>
        </div>

        <BasicForm
          currentStep={this.state.currentStep}
          handleChange={this.onChange}
          formData={this.state.formData}
          handleSubmit={this.submitRegister}
          handleTermsModal={this.handleModal}
          handlePrivacyModal={this.handlePrivacyModal}
          agreeState={this.agreeState}
          agreePrivacy={this.agreePrivacy}
        />

        <div style={{ marginTop: 42 }}>
          <MainFooter />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterBasic);
