import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import "./wizard.css";

export default function Step1(props) {
  // if (props.currentStep !== 1) {
  //   return null;
  // }
  // this.state = {
  //   disabled: false,
  // };

  let btnRef = useRef();

  const style = {
    marginBottom: "1.5em",
  };

  let style1 = {
    maxWidth: "400px",
  };

  let style2 = {
    maxWidth: "120px",
  };

  const handleDisabled = (e) => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
      props.onRegister();
    }
  };

  const cachedReferralId = localStorage.getItem("referralId");

  return (
    <React.Fragment>
      <div class="card bg-light m-4 justify-content-center">
        <div class="card-header text-center">
          {" "}
          Register your Premier MyRep Account
        </div>
        <article class="card-body mx-auto">
          {/* <p class="text-center">Register your Premier MyRep account:</p> */}
          <p class="text-center">1. Create your login credentials</p>
          <p class="text-center">2. Select your account type</p>
          <p class="text-center">3. Enter your payment information</p>
          <form>
            <div className="col-md-12">
              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    {" "}
                    <i class="fa fa-envelope"></i>{" "}
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email"
                  value={props.formData.email.value}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              {" "}
              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    {" "}
                    <i class="fa fa-lock"></i>{" "}
                  </span>
                </div>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  value={props.formData.password.value}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    {" "}
                    <i class="fa fa-lock"></i>{" "}
                  </span>
                </div>
                <input
                  class="form-control"
                  placeholder="Confirm password"
                  type="password"
                  value={props.formData.confirmPassword.value}
                  onChange={props.handleChange}
                  name="confirmPassword"
                />
              </div>
            </div>
            {/* <div class="col-md-4 text-center">
          
              <button
                type="button"
                className="btn btn--outline btn--white"
                // class="btn btn-primary btn-block"
                style={style3}
                onClick={props.createCustomer}
              >
                {" "}
                Create Account{" "}
              </button>
            </div> */}
            <div className="ml-4 row">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label=""
                  onClick={props.agreeState}
                />
              </Form.Group>
              <a href="#" onClick={props.handleTermsModal}>
                Agree to Terms
              </a>
              {/* </div> */}

              <div className="ml-4 row">
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label=""
                    onClick={props.agreePrivacy}
                  />
                </Form.Group>
                <a href="#" onClick={props.handlePrivacyModal}>
                  Agree to Privacy
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="text-center">
                  <button
                    type="button"
                    className="btn btn--outline btn--white acctBtn"
                    // class="btn btn-primary btn-block"
                    onClick={handleDisabled}
                    ref={btnRef}
                    id="acctBtn"
                  >
                    {" "}
                    Create Account{" "}
                  </button>
                </div>
              </div>
            </div>

            <p class="text-center">
              Have an account? <a href="/login">Log In</a>{" "}
            </p>
          </form>
        </article>
      </div>
    </React.Fragment>
  );
}
