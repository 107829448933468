import React from "react";
import TextInput from "../common/TextInput.js";
import PasswordInput from "../common/PasswordInput.js";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import '../../sass/zeplin/BasicForm.scss';
import "./wizard.css";
export default function Step1(props) {
  // if (props.currentStep !== 1) {
  //   return null;
  // }
  // const style = {
  //   display: "block",
  //   textAlign: "center",
  // };

  // let style1 = {
  //   maxWidth: "400px",
  // };

  // let style2 = {
  //   maxWidth: "120px",
  // };

  // let style3 = {
  //   // margin: "0 auto",
  //   // display: "block",
  //   // marginLeft: "auto",
  //   // marignRight: "auto",
  // };

  let style1 = {
    display: "inline-block",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "left",
  };

  let style2 = {
    maxWidth: "1000px",
  };

  let style3 = {
    margin: "0 auto",
  };

  return (
    <React.Fragment>
      <div className="BasicForm">
        <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto'}}>
          <TextInput
            type="email"
            label="Email"
            name="email"
            value={props.formData.email.value}
            onChange={props.handleChange}
            style={{ marginBottom: 32 }}
          />
            <PasswordInput
            type="password"
              name="password"
              label="Password"
              value={props.formData.password.value}
              onChange={props.handleChange}
              containerStyles={{ width: '100%', marginBottom: 32 }}
            />
            <PasswordInput
              containerStyles={{ width: '100%', marginBottom: 32 }}
              label="Confirm password"
              type="password"
              value={props.formData.confirmPassword.value}
              onChange={props.handleChange}
              name="confirmPassword"
            />

          <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20 } }>
            <input
              type="checkbox"
              onClick={props.agreeState}
              style={{ marginRight: 8 }}
            />
            <a href="#" onClick={props.handleTermsModal} style={{marginRight: 24}}>
              Agree to Terms
              </a>

            <input
              type="checkbox"
              onClick={props.agreePrivacy}
              style={{ marginRight: 8 }}
            />
              <a href="#" onClick={props.handlePrivacyModal}>
                Agree to Privacy
              </a>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="text-center">
                  <button
                    type="button"
                    className="Button"
                    style={{ width: 265, }}
                    onClick={props.handleSubmit}
                  >
                    Email verification
                  </button>
                </div>
              </div>
            </div>
            <p class="text-center">
              <a href="/login">Have an account? Log In</a>
            </p>
        </form>
          {/* </div> */}
      </div>
     </React.Fragment>
  );
}
