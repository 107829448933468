import { lazy } from "react";

const allRoutes = [
  // {
  //   path: "/admin/dashboard",
  //   exact: true,
  //   roles: ["Admin"],
  //   component: lazy(() => import("./components/admin/dashboard/AdminDash"))
  // },
  {
    path: "/rep/settings/upgrade",
    exact: true,
    roles: ["Basic"],
    component: lazy(() => import("./components/rep/settings/Upgrade.jsx")),
  },
  {
    path: "/register/company",
    exact: true,
    roles: ["Client", "Basic", "Pro", "Premier"],
    component: lazy(() => import("./components/register/RegisterCompany")),
  },
  {
    path: "/register/location",
    exact: true,
    roles: ["Client", "Basic", "Premier"],
    component: lazy(() => import("./components/register/RegisterLocation")),
  },
  {
    path: "/register/products",
    exact: true,
    roles: ["Client", "Basic", "Premier"],
    component: lazy(() => import("./components/register/RegisterProducts")),
  },
  {
    path: "/register/profile",
    exact: true,
    roles: ["Client", "Basic", "Premier"],
    component: lazy(() => import("./components/register/RegisterProfile.jsx")),
  },
  {
    path: "/client/calendar",
    exact: true,
    roles: ["Client"],
    component: lazy(() => import("./components/client/dashboard/Calendar")),
  },
  {
    path: "/client/reps",
    exact: true,
    roles: ["Client"],
    component: lazy(() => import("./components/client/dashboard/Reps/Reps")),
  },
  {
    path: "/client/settings",
    exact: true,
    roles: ["Client"],
    component: lazy(() => import("./components/client/dashboard/settings/Dieterary.jsx")),
  },
  {
    path: "/client/restrictions",
    exact: true,
    roles: ["Client"],
    component: lazy(() =>
      import("./components/client/dashboard/restrictions/Restrictions.jsx")
    ),
  },
  {
    path: "/messages",
    exact: true,
    roles: ["Admin"],
    component: lazy(() => import("./components/client/dashboard/messages/Messages.jsx")),
  },
  {
    path: "/rep/welcome",
    exact: true,
    roles: ["Basic", "Premier"],
    component: lazy(() => import("./components/WelcomeRepUser.jsx")),
  },
  {
    path: "/rep/calendar",
    exact: true,
    roles: ["Basic", "Premier"],
    component: lazy(() => import("./components/client/dashboard/Calendar")),
  },
  {
    path: "/rep/accounts",
    exact: true,
    roles: ["Basic", "Premier"],
    component: lazy(() => import("./components/rep/accounts/Accounts.jsx")),
  },
  {
    path: "/rep/onboarding",
    roles: ["Basic", "Premier"],
    component: lazy(() => import("./components/common/OnboardingForm.js")),
  },
  {
    path: "/rep/appointments",
    exact: true,
    roles: ["Basic", "Premier"],
    component: lazy(() => import("./components/rep/appointments/Appointment.jsx")),
  },
  {
    path: "/welcome",
    exact: true,
    roles: ["Client", "Basic", "Premier"],
    component: lazy(() => import("./components/client/dashboard/Landing.jsx")),
  },
  {
    path: "/booked",
    exact: true,
    roles: ["Basic", "Premier"],
    component: lazy(() => import("./components/rep/booked/Booked.jsx")),
  },
  {
    path: "/restaurants",
    exact: true,
    roles: ["Basic", "Premier", "Client"],
    component: lazy(() =>
      import("./components/client/dashboard/appointments/YelpResults.jsx")
    ),
  },
  {
    path: "/messages",
    exact: true,
    roles: ["Admin"],
    component: lazy(() => import("./components/rep/messages/Messages.jsx")),
  },
  {
    path: "/referral",
    exact: true,
    roles: ["Basic", "Premier"],
    component: lazy(() => import("./components/rep/referral/Referral.jsx")),
  },
  {
    path: "/appointments",
    exact: true,
    roles: ["Client"],
    component: lazy(() =>
      import("./components/client/dashboard/appointments/Appointments.jsx")
    ),
  },
  {
    path: "/stats",
    exact: true,
    roles: ["Admin"],
    component: lazy(() => import("./components/admin/stats/Blocks.jsx")),
  },
  {
    path: "/assets",
    exact: true,
    roles: ["Admin"],
    component: lazy(() => import("./components/admin/assets/Assets.jsx")),
  },
  {
    path: "/users",
    exact: true,
    roles: ["Admin"],
    component: lazy(() => import("./components/admin/users/UserProfileLite.jsx")),
  },
  {
    path: "/referals",
    exact: true,
    roles: ["Admin"],
    component: lazy(() => import("./components/admin/referals/Referal.jsx")),
  },
  {
    path: "/announcement",
    exact: true,
    roles: ["Admin"],
    component: lazy(() => import("./components/admin/announcement/Announcement.jsx")),
  },
  {
    path: "/profile",
    exact: true,
    roles: ["Client"],
    component: lazy(() =>
      import("./components/client/dashboard/profile/UserProfileLite.jsx")
    ),
  },
  {
    path: "/rep/settings",
    exact: true,
    roles: ["Basic", "Premier"],
    component: lazy(() => import("./components/rep/settings/UserProfileLite.jsx")),
  },
  {
    path: "/login",
    exact: true,
    roles: ["Basic", "Premier", "Client", "Admin"],
    component: lazy(() => import("./components/Login.jsx")),
  },
];

export default allRoutes;
