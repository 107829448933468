import React, { Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Confirm from "./email/Confirm";
import "../services/serviceHelper";
import * as userServices from "../services/userServices";
import LoadingOverlay from "../LoadingOverlay.jsx";
import allRoutes from "../routes.js";
import Home from "../components/Home";
import Login from "../components/Login.jsx";
import debug from "sabio-debug";
import { ToastContainer } from "react-toastify";
import PricingTable from "./PricingTable";
import RegisterUser from "./register/RegisterUser.jsx";
import RegisterClient from "./register/RegisterClient.jsx";
import RegisterBasic from "./register/RegisterBasic.jsx";
// import PasswordForgot from "./pages/PasswordForgot.jsx";
import Referral from "./rep/referral/Referral";
import ReferralConfirm from "./email/ReferralConfirm.jsx";
import CheckYourEmail from "./common/CheckYourEmail";
//import Reset from "./pages/Reset.js";

const AdminDash = React.lazy(() => import("./admin/dashboard/AdminDash"));
const RepDashboard = React.lazy(() =>
  import("../components/rep/dashboard/RepDashboard.jsx")
);
const ClientDashboard = React.lazy(() => import("./client/dashboard/ClientDashboard.js"));
const Error404 = React.lazy(() => import("./pages/Error-404"));
// const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));

const Join = React.lazy(() => import("./Join"));
const Password = React.lazy(() => import("./Password"));
const ResetPass = React.lazy(() => import("./ResetPass"));
// const Contact = React.lazy(() => import("./contact/Contact"));

const hideHeaderList = ["/rep/onboarding"];

const shouldHide = (path) => {
  return hideHeaderList.some((p) => path.includes(p));
};

class AppRoutes extends React.Component {
  state = {
    currentUser: {
      id: null,
      roles: [],
      userName: "",
      email: "",
      isLoggedIn: false,
    },
    routes: [],
    loading: true,
  };

  componentDidMount() {
    userServices.getCurrent().then(this.getCurrentSuccess).catch(this.getCurrentError);
    // this.getCurrentSuccess(dummyUser)
  }

  getCurrentSuccess = (user) => {
    this.setState({
      currentUser: {
        id: user.item.id,
        email: user.item.name,
        userName: user.item.name,
        roles: user.item.roles,
        isLoggedIn: true,
      },
      routes: this.getUserRoutes(user.item.roles[0]),
      loading: false,
    });
  };

  getCurrentError = () => {
    this.setState({
      loading: false,
    });
  };

  getUserRoutes = (role) => {
    return allRoutes.filter((route) => {
      return route.roles.includes(role);
    });
  };

  componentDidUpdate() {
    // Set to initial state upon logout
    if (
      this.props.location.state &&
      this.props.location.state.logout &&
      this.state.currentUser.id > 0
    ) {
      this.logout();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const type =
      nextProps.location.state && !prevState.currentUser.isLoggedIn
        ? nextProps.location.state.type
        : "";

    switch (type) {
      case "login":
        return nextProps.location.state.user.name !== prevState.currentUser.email
          ? {
              currentUser: {
                isLoggedIn: true,
                ...nextProps.location.state.user,
              },
              routes: allRoutes.filter((route) => {
                return route.roles.includes(nextProps.location.state.user.roles[0]);
              }),
              loading: false,
            }
          : null;
      default:
        return prevState.currentUser.isLoggedIn
          ? {
              currentUser: {
                ...prevState.currentUser,
              },
            }
          : null;
    }
  }
  logout = () => {
    this.setState({
      currentUser: {
        id: null,
        roles: [],
        userName: "",
        email: "",
        isLoggedIn: false,
      },
      routes: [],
      loading: false,
    });
  };
  getAdminContent = () => (
    <Suspense fallback={<LoadingOverlay {...this.props} />}>
      <Switch location={this.props.location}>
        <Route
          exact
          path={"/"}
          render={(props) => <Home {...props} currentUser={this.state.currentUser} />}
        />
        {/* <Route path={`/forgotpassword`} component={PasswordForgot} /> */}
        {/* <Route path={`/reset/:token`} component={ResetPassword} /> */}
        {/* <Route path={`/email/confirm/`} component={Confirm} />{" "} */}
        {/* Added this for Admin Access */}
        <AdminDash currentUser={this.state.currentUser}>
          <Suspense fallback={<LoadingOverlay {...this.props} />}>
            <Switch>
              {this.state.routes.map((route, idx) => (
                <Route key={idx + 1} currentUser={this.state.currentUser} {...route} />
              ))}
              <Route
                render={(props) => (
                  <Error404 {...props} currentUser={this.state.currentUser} />
                )}
              />
            </Switch>
          </Suspense>
        </AdminDash>
        <Route
          render={(props) => <Error404 {...props} currentUser={this.state.currentUser} />}
        />
      </Switch>
    </Suspense>
  );
  getBasicContent = () => {
    return (
      <Suspense fallback={<LoadingOverlay {...this.props} />}>
        <Switch location={this.props.location}>
          <Route
            exact
            path={"/"}
            render={(props) => <Home {...props} currentUser={this.state.currentUser} />}
          />
          <RepDashboard
            currentUser={this.state.currentUser}
            hide={shouldHide(this.props.location.pathname)}
          >
            <Suspense fallback={<LoadingOverlay {...this.props} />}>
              <Switch>
                {this.state.routes.map((route, idx) => (
                  <Route key={idx + 1} currentUser={this.state.currentUser} {...route} />
                ))}
                <Route
                  render={(props) => (
                    <Error404
                      {...props}
                      currentUser={this.state.currentUser}
                      expectedRole="Basic"
                    />
                  )}
                />
              </Switch>
            </Suspense>
          </RepDashboard>
          <Route
            render={(props) => (
              <Error404
                {...props}
                currentUser={this.state.currentUser}
                expectedRole="Basic"
              />
            )}
          />
        </Switch>
      </Suspense>
    );
  };
  getProContent = () => (
    <Suspense fallback={<LoadingOverlay {...this.props} />}>
      <Switch location={this.props.location}>
        <Route
          exact
          path={"/"}
          render={(props) => <Home {...props} currentUser={this.state.currentUser} />}
        />
        <RepDashboard
          currentUser={this.state.currentUser}
          hide={shouldHide(this.props.location.url)}
        >
          <Suspense fallback={<LoadingOverlay {...this.props} />}>
            <Switch>
              {this.state.routes.map((route, idx) => (
                <Route key={idx + 1} currentUser={this.state.currentUser} {...route} />
              ))}
              <Route
                render={(props) => (
                  <Error404
                    {...props}
                    currentUser={this.state.currentUser}
                    expectedRole={"Pro"}
                  />
                )}
              />
            </Switch>
          </Suspense>
        </RepDashboard>
        <Route
          render={(props) => (
            <Error404
              {...props}
              currentUser={this.state.currentUser}
              expectedRole={"Pro"}
            />
          )}
        />
      </Switch>
    </Suspense>
  );
  getPremierContent = () => (
    <Suspense fallback={<LoadingOverlay {...this.props} />}>
      <Switch location={this.props.location}>
        <Route
          exact
          path={"/"}
          render={(props) => <Home {...props} currentUser={this.state.currentUser} />}
        />
        <RepDashboard
          currentUser={this.state.currentUser}
          hide={shouldHide(this.props.location.pathname)}
        >
          <Suspense fallback={<LoadingOverlay {...this.props} />}>
            <Switch>
              {this.state.routes.map((route, idx) => (
                <Route key={idx + 1} currentUser={this.state.currentUser} {...route} />
              ))}

              <Route path={`/confirm/:token`} component={Confirm} />
              <Route
                render={(props) => (
                  <Error404
                    {...props}
                    currentUser={this.state.currentUser}
                    expectedRole={"Premier"}
                  />
                )}
              />
            </Switch>
          </Suspense>
        </RepDashboard>
        <Route
          render={(props) => (
            <Error404
              {...props}
              currentUser={this.state.currentUser}
              expectedRole={"Premier"}
            />
          )}
        />
      </Switch>
    </Suspense>
  );
  getClientContent = () => (
    <Suspense fallback={<LoadingOverlay {...this.props} />}>
      <Switch location={this.props.location}>
        <Route
          exact
          path={"/"}
          render={(props) => <Home {...props} currentUser={this.state.currentUser} />}
        />
        <ClientDashboard currentUser={this.state.currentUser}>
          <Suspense fallback={<LoadingOverlay {...this.props} />}>
            <Switch>
              {this.state.routes.map((route, idx) => (
                <Route key={idx + 1} currentUser={this.state.currentUser} {...route} />
              ))}
              <Route
                render={(props) => (
                  <Error404
                    {...props}
                    currentUser={this.state.currentUser}
                    expectedRole="Client"
                  />
                )}
              />
            </Switch>
          </Suspense>
        </ClientDashboard>
        <Route
          render={(props) => (
            <Error404
              {...props}
              currentUser={this.state.currentUser}
              expectedRole={"Client"}
            />
          )}
        />
      </Switch>
    </Suspense>
  );
  getSimpleContent = () => (
    <Suspense fallback={<LoadingOverlay {...this.props} />}>
      <Switch location={this.props.location}>
        <Route
          exact
          path={"/"}
          render={(props) => <Home {...props} currentUser={this.state.currentUser} />}
        />
        {/* <Route path={`/password`} component={PasswordForgot} /> */}

        <Route path={`/password`} component={Password} />
        {/* <Route path={`/reset`} componen={Reset} /> */}
        <Route path={`/reset/:token`} component={ResetPass} />
        <Route path={`/register`} component={Join} />
        {/* <Route path={`/register`} component={Register} /> */}
        <Route path={`/rep/premier`} component={RegisterUser} />
        <Route path={`/client`} component={RegisterClient} />
        <Route path={`/rep/basic`} component={RegisterBasic} />
        <Route path={"/check/email"} component={CheckYourEmail} />
        <Route path={`/pricing`} component={PricingTable} />
        <Route path={`/confirm/:token`} component={Confirm} />
        {/* <Route path={`/rep/setup/pro`} component={RegisterPro} />
        <Route path={`/rep/setup/premium`} component={RegisterPremium} /> */}

        <Route path={`/login`} component={Login} />
        <Route path={`/premier/:token`} component={ReferralConfirm} />

        {/* <Route path={`/register`} component={Register} /> */}
        {/* <Route path={`/contactUs`} component={Contact} /> */}
        {/* <Route path={`/reset/:token`} component={ResetPassword} /> */}
        <Route
          render={(props) => <Error404 {...props} currentUser={this.state.currentUser} />}
        />
      </Switch>
    </Suspense>
  );

  render() {
    return (
      <>
        {this.state.loading && !this.state.isLoggedIn ? (
          <div />
        ) : this.state.currentUser.roles[0] === "Admin" ? (
          this.getAdminContent()
        ) : this.state.currentUser.roles[0] === "Basic" ? (
          this.getBasicContent()
        ) : this.state.currentUser.roles[0] === "Pro" ? (
          this.getProContent()
        ) : this.state.currentUser.roles[0] === "Premier" ? (
          this.getPremierContent()
        ) : this.state.currentUser.roles[0] === "Client" ? (
          this.getClientContent()
        ) : (
          this.getSimpleContent()
        )}
        <ToastContainer />
      </>
    );
  }
}

export default withRouter(AppRoutes);
