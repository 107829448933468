import React, { Component } from "react";
import { FormGroup, Label, Button, Input, Row } from "reactstrap";
import {
  Container,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
} from "shards-react";
import { Formik, Form, Field } from "formik";
import * as userServices from "../../../services/userServices";
import swal from "sweetalert";
import "./referral.css"

class Referral extends Component {
  state = {
    formData: {
      email: "",
    },
  };
  componentDidMount = () => {};

  handleSubmit = (email) => {
    userServices.referral(email).then(this.onSuccess).catch(this.onError);
  };
  onSuccess = (e) => {
    swal({
      icon: "success",
    });
  };

  onError = (e) => {
    swal({
      icon: "error",
    });
  };

  render() {
    const style = {
      marginLeft: "1.5em",
    };
    const styleCard = {
      marginTop: "1.5em",
    };
    return (
      <React.Fragment>
        <Col className="col-8 mt-4">
          <Card small className="card-post d-flex card-post--1">
            <CardHeader className="ml-4">
              <b className="header-referral" >Referral Program Details:</b>
            </CardHeader>
            <CardBody>
              <div className="mt-6 ml-1 col-8">
                <p>
                  <strong className="header-refinvite">Invite Other Reps to Join Premier Now!</strong>
                  <br/> You can get up to $500
                  per year when you refer 10 representatives. <br/>Earn $50 in cash
                  via Zelle for each representative who joins as a PREMIER
                  member. <br/>Your referred reps must apply through the link you
                  provide via email in order for you to earn a bonus.<br/>
                  Refer-a-Rep program is not available for the following
                  customer or account types: Basic
                </p>
              </div>
              {/* <div style={styleCard}> */}
              {/* <div className="card"> */}
              <Formik
                //ref={this.formikRef}
                enableReinitialize={true}
                initialValues={this.state.formData}
                isInitialValid={this.state.isInitialValid}
                onSubmit={this.handleSubmit}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    handleSubmit,
                  } = props;
                  return (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <div style={style}>
                          <Label>To: Email</Label>
                        </div>
                        <div className="referral_email_input d-flex col-md-6">
                          <Field
                            name="email"
                            type="email"
                            values={values.email}
                            placeholder="Enter Email"
                            autoComplete="on"
                            className={
                              errors.email && touched.email
                                ? "form-control errorMessage"
                                : "form-control"
                            }
                          />
                        </div>
                        {errors.email && touched.email && (
                          <span className="input-feedback">{errors.email}</span>
                        )}
                      </FormGroup>
                      <div style={style}>
                        <button
                          type="submit"
                          className="btn btn-primary submit_btn mb-4 mt-4"
                          onClick={this.props.handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              {/* </div> */}
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default Referral;
