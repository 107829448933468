import React, { Component } from "react";
import { Nav, Navbar, NavLink } from "react-bootstrap";
import "./../css/header.css";

// require("history").createBrowserHistory;

class Header extends Component {
  render() {
    const style = {
      paddingRight: "15px",
      borderRight: "2px solid #333",
    };

    return (
      <React.Fragment>
        <Navbar className="navbar-default" bg="light" expand="lg">
          <nav className="navbar navbariL navbar-expand-lg fixed-top navbar-light">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navTop"></Nav>
              <Navbar.Brand className="navbar-brand" href="/">
                <img
                  alt="MyRep"
                  className="logo myreplogo"
                  src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/MyRep-541f14d6-80fb-4888-97ea-a8484bdf9f47-resizedMyrep.png"
                />
              </Navbar.Brand>
              <div className="navbaritems ml-auto">
              <ul class="nav-menu navbarlinks">
            <span style={style}>
              <NavLink className="navbar-link navbarlink" href="/">
                Home
              </NavLink>
            </span>
            <span style={style}>
              <NavLink className="navbar-link navbarlink" href="/register">
                Create an account
              </NavLink>
            </span>

            <NavLink className="navbar-link navbarlink" href="/login">
              Login
            </NavLink>
            </ul></div>
            </Navbar.Collapse>
          </nav>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Header;
