import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import "../css/login.css";
import * as validationHelper from "../helpers/validation.helper";
import "../sass/zeplin/Login.scss";
import * as userService from "../services/userServices";
import PasswordInput from "./common/PasswordInput";
import TextInput from "./common/TextInput";
import TopLogo from "./common/TopLogo";
import Logo from "./common/Logo";
import MainFooter from "./layout/MainFooter";

const validationRules = {
  email: { required: true },
  password: { required: true },
};

export const repInitialPage = "/rep/appointments";

const Login = (props) => {
  const [formData, setFormData] = useState({
    email: { value: "" },
    password: { value: "" },
  });

  useEffect(() => {
    userService
      .getCurrent()
      .then((response) => {
        if (response.item.roles.length > 0) {
          const role = response.item.roles[0];

          if (role === "Client") {
            props.history.push("/client/calendar");
          } else if (["Basic", "Premier", "Admin"].includes(role)) {
            props.history.push(repInitialPage);
          }
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const onCurrentUserSuccess = (response) => {
    let role = response.item.roles[0];
    console.log(role);
    if (role === "Basic") {
      swal("Logged in", "Welcome back!", "success");
      setTimeout(() => {
        swal.close();
        props.history.push(repInitialPage, {
          type: "login",
          user: response.item,
        });
      }, 1000);
    } else if (role === "Premier") {
      swal("Logged in", "Welcome back!", "success");
      setTimeout(() => {
        swal.close();
        props.history.push(repInitialPage, {
          type: "login",
          user: response.item,
        });
      }, 1000);
    } else if (role === "Admin") {
      swal("Logged in", "Welcome back!", "success");
      setTimeout(() => {
        swal.close();
        props.history.push("/admin/dashboard", {
          type: "login",
          user: response.item,
        });
      }, 1000);
    } else if (role === "Client") {
      swal("Logged in", "Welcome back!", "success");
      setTimeout(() => {
        swal.close();
        props.history.push("/client/calendar", {
          type: "login",
          user: response.item,
        });
      }, 1000);
    } else {
    }
  };

  const onError = (err) => {
    let message = err.message || err;

    if (err.response && err.response.status >= 400 && err.response.status < 500) {
      message =
        "Either your username or password is incorrect. Please also make sure that you've checked your e-mail to verify it.";
    }

    swal({
      title: "Sorry, that didn't work",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("", {
          icon: "success",
        });
      }
    });
  };

  const handleSubmit = () => {
    const errors = [];
    Object.keys(formData).forEach((field) => {
      if (!validationHelper.validate(formData[field].value, validationRules[field])) {
        errors.push(`Please fill in ${field}`);
      }
    });

    if (errors.length > 0) {
      return onError(errors.join(". "));
    }

    let item = {
      email: formData.email.value,
      password: formData.password.value,
    };
    userService
      .login(item)
      .then(userService.getCurrent)
      .then(onCurrentUserSuccess)
      .catch(onError);
  };

  const onChange = (field) => (e) => {
    const newFormData = { ...formData };
    newFormData[field].value = e.target.value;
    setFormData(newFormData);
  };

  const style2 = { display: "none" };
  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  };
  const inputStyles = { width: "100%" };

  return (
    <React.Fragment>
      <TopLogo />
      <div id="container" className="Login" style={containerStyles}>
        <Logo
          style={{ margin: "42px auto 0 auto", maxWidth: "50%", width: 168 }}
          className="Logo notMobile"
        />
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              marginBottom: 40,
              maxWidth: 168,
            }}
          ></div>
          <h2 style={{ fontSize: 32 }}>Log in to your account</h2>
        </div>
        <form method="post" className="LoginForm" id="login_form">
          <input type="hidden" name="auth" value="1" />
          <input type="hidden" name="redirect" value="" />
          <TextInput
            style={{ ...inputStyles, marginBottom: 40 }}
            label="Email"
            type="text"
            name="email"
            value={formData.email.value}
            onChange={onChange("email")}
          />
          <PasswordInput
            containerStyles={{ width: "100%" }}
            name="password"
            value={formData.password.value}
            label="Password"
            autocomplete="off"
            onChange={onChange("password")}
          />
          <span class="errormsg"></span>

          <div style={{ width: "100%" }}>
            <Link to="/password" className="Link ForgotPassword">
              Forgot Password?
            </Link>
          </div>
          <a
            className="Button"
            style={{ margin: "0 auto 10px auto" }}
            href="#"
            onClick={handleSubmit}
          >
            <span class="btn__label">
              {/* <img class="lock" src="/images/icon-lock-white.png" /> */}
              Login
            </span>
          </a>
          <Link className="Button ButtonGray" style={{ margin: "0 auto" }} to="/register">
            <span class="btn__label">
              {/* <img class="lock" src="/images/icon-lock-white.png" /> */}
              Create Account
            </span>
          </Link>
        </form>
      </div>
      <div className="half" id="forgotpassdiv" style={style2}>
        <h3>Forgot password</h3>

        <form method="post" id="forgotpassword_form">
          <ul>
            <li>
              <div class="inp-text">
                <TextInput label="Email" type="text" name="email" value="" />
              </div>
            </li>
            <li>
              <a class="btn btn--blue" href="#" onClick="forgotpassword_form.submit();">
                <span class="btn__label">
                  <img class="lock" src="/images/icon-lock-white.png" />
                  Submit
                </span>
              </a>
            </li>
            <li>
              <p>
                <a
                  href="#"
                  onClick="jQuery('#logindiv').show(); jQuery('#forgotpassdiv').hide();"
                >
                  Back to login
                </a>
              </p>
            </li>
          </ul>
        </form>
      </div>
      <MainFooter />
    </React.Fragment>
  );
};

export default Login;
