import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import createHistory from "history/createBrowserHistory";
// import "./index.css";
import { ScrollContext } from "react-router-scroll-4";
import AppRoutes from "./components/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import "./services/serviceHelper.js";
import { useIdleTimer } from "react-idle-timer";
import { logOut, getCurrent } from "./services/userServices.js";
import { Modal } from "react-bootstrap";

const history = createHistory();

const inactivityBeforeWarning = 1000 * 60 * 15; // warning
const inactiveMinsBeforeLogout = 1;
const inactivityBeforeLogout = 1000 * 60 * inactiveMinsBeforeLogout; // 1 min
let timeoutId = null;

const Root = () => {
  const [showInactiveWarningModal, setShowInactiveWarningModal] = useState(false);
  useEffect(()=>{
    setInterval(() => {
      if(localStorage.getItem("hidemodal")==="true")
      {
        console.log(showInactiveWarningModal);
        setShowInactiveWarningModal(false);
        if (timeoutId) clearTimeout(timeoutId);
        setTimeout(()=>{
          localStorage.removeItem("hidemodal");
        },1000)
      }
      }, 1000);
  },[]);
  const handleOnIdle = (event) => {
    getCurrent()
      .then((res) => {
        setShowInactiveWarningModal(true);
        const lastActiveTime = getLastActiveTime();
        timeoutId = setTimeout(() => {
          if (getLastActiveTime() > lastActiveTime) return;
          logOut();
          window.location.replace("/login");
        }, inactivityBeforeLogout);
      })
      .catch((err) => console.error(err));

    // look for a current user
    // if one exists, log them out
  };

  const { getLastActiveTime } = useIdleTimer({
    crossTab: { emitOnAllTabs: true, channelName: "myrep-idle-timer" }, // makes sure that if multiple tabs are opened, being active on any one tab means you are active on all tabs
    timeout: inactivityBeforeWarning, // 15 mins
    onIdle: handleOnIdle,
    handleOnActive: () => {
      if (timeoutId) clearTimeout(timeoutId);
    },
    eventsThrottle: 500, // helps reduce cpu issues
  });

  return (
    <>
      <div>
        <Modal
          show={showInactiveWarningModal}
          onHide={() => {
            setShowInactiveWarningModal(false);
          }}
          centered={true}
        >
          <Modal.Body>
            <h3>Your session is about to end.</h3>
            <p>
              You've been inactive for a while. For your security, we'll automatically
              sign you out in approximately {inactiveMinsBeforeLogout} minute
              {inactiveMinsBeforeLogout > 1 ? "s" : ""}. You may choose "I'm still here"
              to continue or "Log me out" if you're done.
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="Button ButtonGray"
                onClick={() => {
                  logOut().then(() => {
                    window.location.replace("/login");
                  });
                }}
              >
                Log me out
              </button>
              <button
                className="Button"
                onClick={() => {
                  localStorage.setItem("hidemodal","true");
                  // Clicking this button (actually moving the mouse to be able to click on this button) will cause the timer to reset
                }}
              >
                I'm still here
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <ScrollContext>
        <AppRoutes />
      </ScrollContext>
    </>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById("root")
);
