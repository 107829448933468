import React from "react";
import "../App.css";
import Header from "./Header";
import { Link } from "react-router-dom";
import "../css/animate-3.7.0.css";
import "../css/font-awesome-4.7.0.min.css";
import "../css/bootstrap-4.1.3.min.css";
import "../css/owl-carousel.min.css";
import "../css/jquery.datetimepicker.min.css";
import "../css/linearicons.css";
import "../css/style.css";
import MainFooter from "../components/layout/MainFooter";

function Home() {
  return (
    <React.Fragment>
      {/* <div class="preloader">
        <div class="spinner"></div>
      </div> */}

      <Header />
      <section className="banner-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <h4>Accomplish More Together</h4>
              <h2 className="home_quote">
                Bridging the gap between providers and representatives
              </h2>
              <p>Modernize the process of scheduling</p>
              <Link to="/register" className="template-btn mt-3">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="feature-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-feature text-center item-padding">
                {/* <img
                  src="https://myrep-assets.s3-us-west-1.amazonaws.com/home/IMG_0140.jpg"
                  alt=""
                  style={style2}
                /> */}
                <h3>Cloud-Based Application</h3>
                {/* <p class="pt-3">No Software Needed</p> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-feature text-center item-padding mt-4 mt-md-0">
                {/* <img
                  src="https://myrep-assets.s3-us-west-1.amazonaws.com/home/IMG_0144.jpg"
                  alt=""
                  style={style2}
                /> */}
                <h3 className="free_practices">Free for Practices</h3>
                {/* <p class="pt-3">Stop wasting precious Staff Time</p> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-feature text-center item-padding mt-4 mt-lg-0">
                {/* <img
                  src="https://myrep-assets.s3-us-west-1.amazonaws.com/home/IMG_0142.jpg"
                  alt=""
                  style={style2}
                /> */}
                <h3>Over 50 years of Industry Experience</h3>
                {/* <p class="pt-3">
                  Designed to address Representative and Office Needs
                </p> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-feature text-center item-padding mt-4 mt-lg-0">
                {/* <img
                  src="https://myrep-assets.s3-us-west-1.amazonaws.com/home/IMG_0145.jpg"
                  alt=""
                  style={style3}
                /> */}
                <h3 className="easy_to_use">Easy to Use</h3>
                {/* <p class="pt-3">Book and Control from Anywhere</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="welcome-area section-padding3">
        <div className="container container-fluidd mx-auto">
          <div className="row welcome-row">
            <div className="col-lg-6 align-self-center">
              <div className="welcome-img">
                <img src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/myRep_desktop.png" />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="welcome-text mt-5 mt-lg-0">
                <h2>Welcome to MyRep</h2>
                <p className="pt-3">
                  For practices, MyRep was created to minimize the disruption
                  and staff time lost to coordinating representative
                  interactions. Your representatives will not have to physically
                  be at your office when your calendar opens and they will be
                  able to book virtual or in office appointments without having
                  to contact you. If a representative has to cancel, no
                  problem-the appointment will automatically go out to all the
                  other representatives connected to your office.
                </p>
                <p>
                  For representatives, especially those who cover large
                  territories, MyRep allows you to book from anywhere. Whether
                  you're away at a meeting or on vacation, you will no longer
                  have to worry about missing an appointment opportunity. With
                  MyRep Premier you will be able to receive real-time alerts to
                  let you know when offices have opened their calendars,
                  requested samples or if a canceled appointment becomes
                  available ... and so much more.
                </p>
                <p>Thank you for choosing MyRep!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="department-area section-padding4">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-3">
              <div class="section-top text-center">
                <h2>Popular department</h2>
                <p>
                  Green above he cattle god saw day multiply under fill in the
                  cattle fowl a all, living, tree word link available in the
                  service for subdue fruit.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="department-slider owl-carousel">
                <div class="single-slide">
                  <div class="slide-img">
                    <img
                      src="./images/department1.jpg"
                      alt=""
                      class="img-fluid"
                    />
                    <div class="hover-state">
                      <a href="#">
                        <i class="fa fa-stethoscope"></i>
                      </a>
                    </div>
                  </div>
                  <div class="single-department item-padding text-center">
                    <h3>cardiac clinic</h3>
                    <p>
                      Hath creeping subdue he fish gred face whose spirit that
                      seasons today multiply female midst upon
                    </p>
                  </div>
                </div>
                <div class="single-slide">
                  <div class="slide-img">
                    <img
                      src="./images/department2.jpg"
                      alt=""
                      class="img-fluid"
                    />
                    <div class="hover-state">
                      <a href="departments.html">
                        <i class="fa fa-stethoscope"></i>
                      </a>
                    </div>
                  </div>
                  <div class="single-department item-padding text-center">
                    <h3>plastic surgery</h3>
                    <p>
                      Hath creeping subdue he fish gred face whose spirit that
                      seasons today multiply female midst upon
                    </p>
                  </div>
                </div>
                <div class="single-slide">
                  <div class="slide-img">
                    <img
                      src="./images/department3.jpg"
                      alt=""
                      class="img-fluid"
                    />
                    <div class="hover-state">
                      <a href="departments.html">
                        <i class="fa fa-stethoscope"></i>
                      </a>
                    </div>
                  </div>
                  <div class="single-department item-padding text-center">
                    <h3>dental clinic</h3>
                    <p>
                      Hath creeping subdue he fish gred face whose spirit that
                      seasons today multiply female midst upon
                    </p>
                  </div>
                </div>
                <div class="single-slide">
                  <div class="slide-img">
                    <img
                      src="./images/department1.jpg"
                      alt=""
                      class="img-fluid"
                    />
                    <div class="hover-state">
                      <a href="departments.html">
                        <i class="fa fa-stethoscope"></i>
                      </a>
                    </div>
                  </div>
                  <div class="single-department item-padding text-center">
                    <h3>cardiac clinic</h3>
                    <p>
                      Hath creeping subdue he fish gred face whose spirit that
                      seasons today multiply female midst upon
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section class="patient-area section-padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-3">
              <div class="section-top text-center">
                <h2>Patient are saying</h2>
                <p>
                  Green above he cattle god saw day multiply under fill in the
                  cattle fowl a all, living, tree word link available in the
                  service for subdue fruit.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-5">
              <div class="single-patient mb-4">
                <img src="./images/patient1.png" alt="" />
                <h3>daren jhonson</h3>
                <h5>hp specialist</h5>
                <p class="pt-3">
                  Elementum libero hac leo integer. Risus hac road parturient
                  feugiat. Litora cursus hendrerit bib elit Tempus inceptos
                  posuere metus.
                </p>
              </div>
              <div class="single-patient">
                <img src="./images/patient2.png" alt="" />
                <h3>black heiden</h3>
                <h5>hp specialist</h5>
                <p class="pt-3">
                  Elementum libero hac leo integer. Risus hac road parturient
                  feugiat. Litora cursus hendrerit bib elit Tempus inceptos
                  posuere metus.
                </p>
              </div>
            </div>
            <div class="col-lg-5 offset-lg-1 align-self-center">
              <div class="appointment-form text-center mt-5 mt-lg-0">
                <h3 class="mb-5">appointment now</h3>
                <form action="#">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Your Name"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'Your Name'"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      placeholder="Your Email"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'Your Email'"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      id="datepicker"
                      placeholder="Date"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'Date'"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      name="message"
                      cols="20"
                      rows="7"
                      placeholder="Message"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'Message'"
                      required
                    ></textarea>
                  </div>
                  <a href="#" class="template-btn">
                    appointment now
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section class="specialist-area section-padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-3">
              <div class="section-top text-center">
                <h2>Our specialish</h2>
                <p>
                  Green above he cattle god saw day multiply under fill in the
                  cattle fowl a all, living, tree word link available in the
                  service for subdue fruit.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <div class="single-doctor mb-4 mb-lg-0">
                <div class="doctor-img">
                  <img
                    src="assets/images/doctor1.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
                <div class="content-area">
                  <div class="doctor-name text-center">
                    <h3>ethel davis</h3>
                    <h6>sr. faculty data science</h6>
                  </div>
                  <div class="doctor-text text-center">
                    <p>
                      If you are looking at blank cassettes on the web, you may
                      be very confused at the.
                    </p>
                    <ul class="doctor-icon">
                      <li>
                        <a>
                          <i class="fa fa-facebook"></i>
                          <i class="fa fa-twitter"></i>
                          <i class="fa fa-linkedin"></i>
                          <i class="fa fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single-doctor mb-4 mb-lg-0">
                <div class="doctor-img">
                  <img
                    src="assets/images/doctor1.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
                <div class="content-area">
                  <div class="doctor-name text-center">
                    <h3>ethel davis</h3>
                    <h6>sr. faculty data science</h6>
                  </div>
                  <div class="doctor-text text-center">
                    <p>
                      If you are looking at blank cassettes on the web, you may
                      be very confused at the.
                    </p>
                    <ul class="doctor-icon">
                      <li>
                        <a>
                          <i class="fa fa-facebook"></i>
                          <i class="fa fa-twitter"></i>
                          <i class="fa fa-linkedin"></i>
                          <i class="fa fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single-doctor mb-4 mb-lg-0">
                <div class="doctor-img">
                  <img
                    src="assets/images/doctor1.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
                <div class="content-area">
                  <div class="doctor-name text-center">
                    <h3>ethel davis</h3>
                    <h6>sr. faculty data science</h6>
                  </div>
                  <div class="doctor-text text-center">
                    <p>
                      If you are looking at blank cassettes on the web, you may
                      be very confused at the.
                    </p>
                    <ul class="doctor-icon">
                      <li>
                        <a>
                          <i class="fa fa-facebook"></i>
                          <i class="fa fa-twitter"></i>
                          <i class="fa fa-linkedin"></i>
                          <i class="fa fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single-doctor mb-4 mb-lg-0">
                <div class="doctor-img">
                  <img
                    src="assets/images/doctor1.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
                <div class="content-area">
                  <div class="doctor-name text-center">
                    <h3>ethel davis</h3>
                    <h6>sr. faculty data science</h6>
                  </div>
                  <div class="doctor-text text-center">
                    <p>
                      If you are looking at blank cassettes on the web, you may
                      be very confused at the.
                    </p>
                    <ul class="doctor-icon">
                      <li>
                        <a>
                          <i class="fa fa-facebook"></i>
                          <i class="fa fa-twitter"></i>
                          <i class="fa fa-linkedin"></i>
                          <i class="fa fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <MainFooter />
    </React.Fragment>
  );
}

export default Home;
