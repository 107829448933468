import React from "react";
import * as EmailService from "../../services/emailService";
//import loaderImg from "../../assets/images/newloader.gif";

const useQueryParams = () => {
  const params = new URLSearchParams(window ? window.location.search : {});

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop);
    },
  });
};

class Confirm extends React.Component {
  constructor(props) {
    super(props);

    };

  componentDidMount() {
    var guid = this.props.match.params.token;
    var regEx =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    // TODO - rabidly insecure solution. Come up with something that is on the backend
    if (regEx.test(guid) === true) {
      const { isProfileConfirm } = useQueryParams();
      if (isProfileConfirm) {
        EmailService.userProfileConfirm(guid)
          // .then(this.onConfirmSuccess)
          .catch(this.onConfirmFailure);
        // setTimeout(() => {
        //   this.props.history.push("/login");
        // }, 6000);
      } else {
        EmailService.confirmAcct(guid)
          .then(() => {
            setTimeout(() => {
              this.props.history.push("/login");
            }, 6000);
          })
          .catch(this.onConfirmFailure);
      }
    }
  }

  onConfirmSuccess = () => {
    this.props.history.push("/login");
  };

  onConfirmFailure = () => {
    this.props.history.push("/Error-404");
  };

  render() {
    const { isProfileConfirm } = useQueryParams();
    return (
      <div>
        <div>
          <div style={{ margin: "0 auto", textAlign: "center" }}>
            <h1 style={{ borderBottom: 0 }}>
              {isProfileConfirm ? "User Account" : "Email"} Confirmed!
            </h1>
            {!isProfileConfirm && (
              <>
                <p>Thank you for signing up!</p>
                <p>In a few seconds you will be redirected to the login page.</p>
              </>
            )}
            {isProfileConfirm && (
              <>
                <p>
                  This accounts has been confirmed and is now able to access the platform
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Confirm;
