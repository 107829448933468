import axios from "axios";
import * as helper from "./serviceHelper";

let { REACT_APP_API_URL } = process.env;
const endpoint = REACT_APP_API_URL + "/user"; //production

let register = (payload) => {
  const config = {
    method: "POST",
    url: endpoint + "/register",
    withCredentials: true,
    data: payload,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getCreateCustomer = (payload) => {
  const config = {
    method: "GET",
    url: REACT_APP_API_URL + "/payment/customer",
    data: payload,
    withCredentials: true,
    crossdomain: true,
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let updateRole = (payload, id) => {
  const config = {
    data: payload,
    method: "PUT",
    url: endpoint + "/role/" + id,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };

  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let createSubscription = (payload) => {
  const config = {
    method: "POST",
    url: REACT_APP_API_URL + "/payment/subscribe",
    data: payload,
    withCredentials: true,
    crossdomain: true,

    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getCurrent = () => {
  const config = {
    method: "GET",
    url: endpoint + "/current",

    withCredentials: true,
    crossdomain: true,
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let login = (payload) => {
  const config = {
    method: "POST",
    url: endpoint + "/login",
    withCredentials: true,
    data: payload,
    crossdomain: true,
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let referral = (payload) => {
  const config = {
    method: "POST",
    url: endpoint + "/referral",
    withCredentials: true,
    data: payload,
    crossdomain: true,
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let logOut = () => {
  const config = {
    method: "GET",
    url: endpoint + "/logout",
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let emailToVerify = (email) => {
  const config = {
    method: "GET",
    url: endpoint + "/api/user/reconfirm?email=" + email,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let selectById = (id) => {
  const config = {
    method: "GET",
    url: endpoint + "/profile/" + id,
    withCredentials: true,
    crossdomain: true,
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let selectRegistratinNameById = (id) => {
  const config = {
    method: "GET",
    url: endpoint + "/name/" + id,
    withCredentials: true,
    crossdomain: true,
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getFriendId = () => {
  const config = {
    method: "GET",
    url: endpoint + "/friendIds",
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

export {
  getFriendId,
  getCurrent,
  selectRegistratinNameById,
  selectById,
  login,
  referral,
  register,
  logOut,
  emailToVerify,
  getCreateCustomer,
  createSubscription,
  updateRole,
};
