import React from "react";

// An input with an inline label
const TextInput = ({ html = 'input', label = "", name = "", containerStyles = {}, ...rest }) => {
  const Type = html;
  const input = <Type type="text" className="Input" name={ name } {...rest}/>;

  if (!label) return input;

  return (
    <div
      className="TextInputContainer"
      style={{ position: "relative", width: '100%', ...containerStyles }}
    >
      <label
        style={{ color: "#1f4784", position: "absolute", left: 16, top: 0, fontSize: 12, width: '100%' }}
        htmlFor={name}
      >
        {label}
      </label>
      {input}
    </div>
  );
};

export default TextInput;
